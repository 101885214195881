import { createLogger } from '@scm/log'
import {
  FetchNavigationRecentContentArgs,
  NavigationRecentContentItem,
} from './types'

export const HOME_PATHNAME = '/'
export const WEATHER_WIDGET_API_PATH = '/api/current-weather?zipCode='
export const WEATHER_DETAIL_URL = '/weather?zipCode='
export const MARKETS_DATA_WIDGET_API_PATH = '/api/markets-data-widgets?zipCode='

const log = createLogger('clientFetchData')

export const fetchWeatherWidget = async (zipCode: string) => {
  try {
    const url = `${WEATHER_WIDGET_API_PATH}${zipCode}`
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`Failed to fetch weather data: ${response.statusText}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    log.error('fetchWeatherWidget error:', error)
    throw error
  }
}

export const fetchMarketsDataWidgets = async (zipCode: string) => {
  try {
    const url = `${MARKETS_DATA_WIDGET_API_PATH}${zipCode}`
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`Failed to fetch market data: ${response.statusText}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    log.error('fetchMarketsDataWidgets error:', error)
    throw error
  }
}

export const fetchNavigationRecentContent = async (
  links: FetchNavigationRecentContentArgs[],
): Promise<NavigationRecentContentItem[]> => {
  try {
    const response = await fetch('/api/navigation-recent-content', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ links }),
    })
    const resultData = await response.json()

    const { success, navigationRecentContent } = resultData

    if (!success) {
      throw new Error(`Failed to fetch navigation recent content`)
    }

    return navigationRecentContent
  } catch (error) {
    log.error('fetchNavigationRecentContent error:', error)

    return [] as NavigationRecentContentItem[]
  }
}
